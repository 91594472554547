#headTitle {
    min-width: 90%;
}
#headRow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900 !important;
    background-color: #15bd78;
    color: white;
    font-size: 3.5vw;
}
.custom {
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 20px;
    border: #15bd78 solid 1px;
}

.custom > .link {
    float: right;
    width: 300px;
    background-color: #161793;
}

.custom > .title {
    color: #161793;
}
.left-menu {
    display: flex;
}
