.App {
    /* text-align: center; */
}

* {
    margin: 0;
    padding: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
body {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    text-align: left;
    margin: 0% 3.3%;
    width: 100%;

    /* display: flex; */
    /* height: 100vh;   */
}

.container {
    min-width: 90%;
}
/* .container{
  
  min-height:100vh; */
/* padding-left: 4%;
  padding-right: 4%; */
/* box-sizing: border-box; */
/* overflow: hidden;
} */

.contact-header {
    /* display: flex; */
    /* display: inline-block; */
    margin-bottom: 3rem;
}
.contact-header .header-text {
    /* display: flex; */
    /* align-self: flex-end; */
    font-weight: 900 !important;

    color: #15bd78;
    font-size: -webkit-xxx-large;
}

.labels {
    padding-top: 7px;
    /* padding-bottom: 3px; */
    /* font-weight: 100; */
    color: black;
}
#EODFunction {
    border: 1px solid gray !important ;
    color: gray;
    margin-left: 12px;
}
#priceEntryDate {
    border: 1px solid gray !important ;
    color: gray;
}
#physicalEvengPremium,
#physicalEvengEUR_USD,
#physicalEvengGasOilRef,
#physicalEvengSpotMonth {
    border: 1px solid gray !important ;
    color: white;
    background-color: #120e52;
}

#physicalEvengGasOilRefError {
    border: 1px solid gray !important ;
    color: white;
    background-color: red;
}

#physicalPremium {
    border: 1px solid gray !important ;
    color: gray;
}

.content {
    border: 3px solid;
    padding: 50px;
    margin-top: 30px;
}

.spreadStyles {
    border: 3px solid;
    padding: 50px;
    margin-top: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.spreadSheetStyle {
    border: 3px solid;
    width: 600px;
}

.row {
    /* display:block; */
    /* justify-content: space-between; */
    /* align-items:center; */
    /* text-align: center; */
    /* margin: 10px 0;   */
}

#datepicker::placeholder {
    color: black !important;
}

.form-control {
    border-radius: 8px !important;
}

.btn-submit {
    /* background: #15BD78 !important; */
    background: #70ad47 !important;

    /* color: #113B8C !important;
   */
    color: black !important;
    border-radius: 8px !important;
    width: 100%;
    display: block;
    margin: 10px 0;
}

.btn-footer {
    /* background: #15BD78 !important; */
    background: #70ad47 !important;

    /* color: #113B8C !important;
     */
    color: black !important;
    border-radius: 8px !important;
    /* display: block;    */
    margin-top: -3px;
}
.updatePrice {
    margin-top: 20px !important;
    color: rgba(112, 173, 71, 255) !important;
}
.info {
    border: 1px solid gray !important ;
    color: gray !important;
    width: 100%;

    display: block;
    margin: 10px 0;
    text-align: left !important;
}

.info:hover {
    border: 1px solid black;
}

.btn-gray {
    border: 1px solid gray !important ;
    background-color: lightgray !important ;
    color: black !important;
    width: 100%;
    display: block;
    margin: 10px 0;
    text-align: left !important;
}

footer {
    margin-top: 30px;
}

.info-form .form-input {
    margin: 0% 4%;
}

div.form-group-heading h2 {
    font-size: -webkit-xxx-large;
    text-align: left !important;
    margin-left: 1% !important;
    font-weight: 900;
    color: #15bd78;
}
.form-group {
    padding-left: 5% !important;
}
.form-group label {
    padding-top: 7px;
    font-weight: 100;
}
/* div.form-group-heading h2 {
    font-size: -webkit-xxx-large;
    text-align: left !important;
    margin-left: 1% !important;
    font-weight: 900;
    color: #15bd78;
} */

#cover-spin {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    display: none;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#cover-spin::after {
    content: "";
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}
/* .modal-title{
  font-weight: bold;
  font-size: x-large;
  padding: 20px;
  padding-bottom: 0;
  border-bottom: 1px solid #e5e5e5;
} */

/* .modal-dialog
{
     
  margin-left: 2vw;    
  width: 96vw ;   
  height: 85vh !important;
  

} */

.global-footer {
    height: 76px;
    background: #111290;
    padding: 26px;
    position: fixed;
    width: 90%;
    bottom: 0;
    /* margin-right: 300px; */

    z-index: 99;
    /* margin-top: 600px; */
}

.footer-section span {
    float: left;
    margin-bottom: 0.5%;
}

.footer-section-right span {
    float: right;
    margin-bottom: 0.5%;
}

.text-color {
    color: #15bd78;
}
.confidential-text {
    /* float: right;
    margin: 0 auto; */
}

.gc-columnHeader-highlight {
    background-color: black;

    color: White;
}
.gc-columnHeader-selected {
    color: black;
    background-color: #291fb3;
    background-image: none;
    border-style: solid;
    border-left-color: #d2d2d2 !important;
    border-right-color: #9c9c9c !important;
    border-bottom-color: #999999 !important;
}

.pricing-text {
    float: left;
    margin-left: 10px;
}
.margin-top5px {
    margin-top: -5px;
}
.margin-top64px {
    margin-top: -39px;
}

.margin-top25px {
    margin-top: -25px;
}

.margin-left350px {
    margin-left: 36%;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.pricing-div {
    float: left;
    margin-left: 4%;
}

hr.vertical {
    width: 0px;
    height: 100%;
    /* or height in PX */
}

#verticle-line {
    width: 1px;
    min-height: 40px;
    background: #15bd78;
}

.margin-left45px {
    margin-left: 35px;
}
/* 
.margin-left6
{
  margin-left : 4%
} */

.margin-left10 {
    margin-left: 10%;
}

.text-color-red {
    color: red;
}

.margin-top6 {
    margin-top: -6px;
}

.rw-combobox {
    padding: 0;
}

.fieldDesign,
.rw-widget-picker {
    border: 1px solid gray !important;
    color: gray;
}
.mar-22 {
    margin-top: 22px;
}

.error {
    color: red;
}

#formCarbonCreditPrice {
    margin-top: 10px;
}

#physicalEvengEUR_USD {
    cursor: not-allowed;
}
